import { useEffect, useMemo, useState } from 'react';
import useGetOwnedWrappedCollections from '../../hooks/useGetOwnedWrappedCollections';
import { useAccount, useWalletClient } from 'wagmi';
import { UWSelectItem } from './UWSelectItem';
import useGetUwWrap from '../../hooks/useGetUwWrap';
import { FaSearch } from 'react-icons/fa';
import { UWChains } from './UWChains';
import { UWCollections } from './UWCollections';
import { usePagination } from '../../hooks/usePagination';
import UWPagination from './UWPagination';
import Status from './statusButtons';
import { useDispatch } from 'react-redux';
import { setModal } from '../../redux/counterSlice';
import { supportedChains as chains } from '../../helpers/getSupportedChain';
import useGetUwUnwrapCount from '../../hooks/useGetUwUnwrapCount';
import { UWUnwrapCollections } from './UWUnwrapCollections';
import { errorToast } from '../toast';
import { Tooltip } from '@mui/material';
import { BsInfo } from 'react-icons/bs';
import { useRouter } from 'next/router';
import qs from 'query-string';
import { SearchInput } from '../SearchInput/search_input';
import DropdownDynamic from '../dropdown/dropdown_dynamic';
import { useDataRefreshContext } from '../refreshContext';

const supportedChains = chains.filter((chain) => chain.uwSupport);

export const UniversalWrapper = () => {
  const router = useRouter();
  const { refreshHooks } = useDataRefreshContext();
  const query = qs.parse(window?.location?.search);
  const [status, setStatus] = useState(query?.wrap == 'false' ? 'unwrapping' : 'wrapping');
  const [firstLoad, setFirstLoad] = useState(true);
  const isWrapping = status === 'wrapping';
  const { address: walletAddress } = useAccount();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [transferAndSell, setTransferAndSell] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [filterText, setFilterText] = useState('');
  const dispatch = useDispatch();
  const { data: currentWallet } = useWalletClient();
  const [approvedCollections, setApprovedCollections] = useState({});

  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState('recent');
  const [selectedChainIds, setSelectedChainIds] = useState([]);
  const [useSamePrice, setUseSamePrice] = useState(false);
  const [samePriceValue, setSamePriceValue] = useState();

  const getInitialControlDisabled = () => {
    if (!isWrapping) {
      return !items?.length;
    } else {
      return filteredCollections ? filteredCollections.length == 0 || !items.length : true;
    }
  };
  const [controlDisabled, setControlDisabled] = useState(getInitialControlDisabled());

  const { data: collections } = useGetOwnedWrappedCollections(walletAddress, selectedChainIds);

  const { data: dn404Collections } = useGetUwUnwrapCount(walletAddress, selectedChainIds);

  const collectionAddresses = collections?.map((i) => i.address) || [];
  const filteredCollections = useMemo(() => {
    if (collections?.length > 0) {
      const filter = collections.filter((collection) => {
        const textMatch =
          !filterText || collection.name.toLowerCase().includes(filterText.toLowerCase());

        const chainIdMatch =
          selectedChainIds.length === 0 || selectedChainIds.includes(collection.chain);

        const correctChain = supportedChains.find((chain) => chain.hexId === collection.chain);

        const collectionIsNotDN404 = collection.contractType !== 'DN404';

        return textMatch && chainIdMatch && correctChain && collectionIsNotDN404;
      });
      return filter;
    } else {
      return [];
    }
  }, [collections, selectedChainIds, filterText, status]);

  const filteredDn404Collections = useMemo(() => {
    if (dn404Collections?.length > 0) {
      const filter = dn404Collections.filter((collection) => {
        const textMatch =
          !filterText || collection.name.toLowerCase().includes(filterText.toLowerCase());

        const chainIdMatch =
          selectedChainIds.length === 0 || selectedChainIds.includes(collection.chain);

        const correctChain = supportedChains.find((chain) => chain.hexId === collection.chain);

        const collectionIsNotDN404 = collection.contractType !== 'DN404';

        return textMatch && chainIdMatch && correctChain && collectionIsNotDN404;
      });

      return filter;
    } else {
      return [];
    }
  }, [dn404Collections, selectedChainIds, filterText, status]);

  let tokenIds = null;
  if (query?.tokenIds) {
    tokenIds = typeof query.tokenIds === 'string' ? query.tokenIds.split(',') : query.tokenIds;
  }
  const {
    data: items,
    totalCount,
    totalPages,
    page,
    changePage,
    isLoading,
  } = usePagination({
    useFetch: useGetUwWrap,
    collectionAddresses,
    walletAddress,
    defaultLimit: 10,
    defaultPage: 0,
    is_wrap: status === 'unwrapping' /*  buy_now: status === 'onSale' ? true : false, */,
    sort,
    selectedCollection:
      query?.collectionAddress && query?.wrap == 'true' && isWrapping
        ? [query.collectionAddress]
        : selectedCollection,
    chainId: selectedChainIds,
    search: searchText,
    ...(query?.wrap == 'true' && isWrapping ? { tokenIds } : {}),
  });

  const sortSelectItems = [
    {
      id: 'recent',
      text: 'Recent',
    },
    {
      id: 'tokenId',
      text: 'Token ID',
    },
  ];

  // const items = rawItems.filter((item) => {
  //   const found = isWrapping
  //     ? filteredCollections.some((collection) => collection.address === item.collectionAddress)
  //     : filteredDn404Collections.some(
  //         (collection) => collection.address === item.collectionAddress,
  //       );

  //   return !!found;
  // });

  const approvedCollectionAddresses = collectionAddresses.filter(
    (address) => approvedCollections[address],
  );

  const goToPage = (p) => {
    if (p >= 0 && p < totalPages) {
      changePage(p);
      setSelectedItems([]);
      setSelectAll(false);
      setUseSamePrice(false);
      setSamePriceValue('');
    }
  };

  const toggleSelectAll = () => {
    if (!selectAll && items) {
      const selectedItems = isWrapping
        ? items.filter((item) => approvedCollectionAddresses.includes(item.collectionAddress))
        : items;
      setSelectedItems(selectedItems);
    } else {
      setSelectedItems([]);
    }
    setSelectAll(!selectAll);
  };

  const handleWrap = () => {
    dispatch(
      setModal({
        key: 'uwWrapModal',
        data: {
          ...selectedItems,
          useBatchWrap: true,
          chain: selectedItems[0].collection.chain,
        },
      }),
    );
  };

  const handleUnwrap = () => {
    // Check item counts
    // if specific collection it

    // Count selected items by collection addresses
    const newCollectionAddressCounts = new Map();

    // Increment the count of the collection address
    selectedItems.forEach((item) => {
      const count = newCollectionAddressCounts.get(item.collectionAddress) || 0;
      newCollectionAddressCounts.set(item.collectionAddress, count + 1);
    });

    let isAllowed = true;

    // Check if the count of the selected items is greater than the count of the collection
    dn404Collections.forEach((collection) => {
      const count = newCollectionAddressCounts.get(collection.address) || 0;

      if (count > collection.count) {
        isAllowed = false;
        errorToast(
          `In collection ${collection.name}, you can only unwrap ${collection.count} item${
            collection.count > 1 ? 's' : ''
          }`,
        );
      }
    });

    if (!isAllowed) return;
    dispatch(
      setModal({
        key: 'uwUnwrapModal',
        data: {
          ...selectedItems,
          useBatchUnwrap: true,
          chain: selectedItems[0].collection.chain,
        },
      }),
    );
  };

  const resetSelections = () => {
    setSelectAll(false);
    setUseSamePrice(false);
    setSearchText('');
    setSort('recent');
    setSamePriceValue('');
    setSelectedItems([]);
    setTransferAndSell(true);
    changePage(0);
  };

  const buttonDisabled = isWrapping
    ? (selectedItems?.length && transferAndSell) || !selectedItems?.length
    : !selectedItems?.length;

  useEffect(() => {
    const selectedItemWithDifferentChainId = selectedItems?.find((item, index, array) => {
      return item?.collection?.chain !== array[0].collection?.chain;
    });

    if (!items || selectedItems?.length === 0) {
      setSelectAll(false);
      return;
    }

    setTransferAndSell(selectedItemWithDifferentChainId);

    const approvedItems = isWrapping
      ? items.filter((item) => approvedCollectionAddresses.includes(item.collectionAddress))
      : items;

    if (selectedItems?.length === approvedItems?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedItems, samePriceValue, items]);

  useEffect(() => {
    resetSelections();
  }, [selectedCollection, status]);

  useEffect(() => {
    setControlDisabled(!items.length);
  }, [isWrapping, items, selectedCollection]);

  useEffect(() => {
    if (isWrapping) {
      const selectedList = Object.entries(approvedCollections)
        .filter(([_, v]) => !!v)
        .map(([k, _]) => k);

      setSelectedCollection((s) => [...s, ...selectedList]);

      if (typeof approvedCollections === 'object') {
        const approved = Object.values(approvedCollections).filter((a) => !!a).length > 0;

        if (isWrapping) setControlDisabled(approved && !items.length);
        else setControlDisabled(!items.length);
      }
    } else {
      setSelectedCollection(filteredDn404Collections.map((c) => c.address));
    }
  }, [approvedCollections, status]);

  useEffect(() => {
    resetSelections();
  }, [status]);

  useEffect(() => {
    if (query?.tokenIds && !isLoading && items?.length && firstLoad) {
      const tokenIds =
        typeof query.tokenIds === 'string' ? query.tokenIds.split(',') : query.tokenIds;
      const selected = items.filter((item) => tokenIds.includes(item.itemId));
      setFirstLoad(false);
      setSelectedItems(selected);
    }
  }, [items, query?.tokenIds]);

  return (
    <div className='flex min-w-[400px] flex-col gap-8 border border-jacarta-100 pb-4 pt-4 dark:border-jacarta-600 lg:flex-row'>
      {/*---------------------------------1. Approval---------------------------------------*/}
      {/* <div className='grid gap-8 overflow-x-auto rounded-lg p-4 pb-4 pt-4 dark:border-jacarta-600'> */}

      <div className='w-full px-6 lg:w-[368px]'>
        {/* <div className='col-span-12 justify-center px-6 lg:col-span-4'> */}
        <div className="font-['Cal Sans'] mt-3 mb-6 text-xl font-semibold text-accent">
          1. Approval
        </div>

        <div className="-font-['Roboto'] border-b border-jacarta-100 pb-6 text-base font-normal leading-normal dark:border-jacarta-600">
          You need to approve the collection before wrapping. It costs a one-time gas for each
          collection.
        </div>

        <div className="font-['Cal Sans'] pt-2 text-base font-semibold">Status</div>

        <Status status={status} setStatus={setStatus} />

        <div className='flex flex-col gap-4'>
          <div className=" font-['Cal Sans'] mb-2 text-base font-semibold">Chain</div>
          {supportedChains.map((chain) => (
            <UWChains
              key={`${chain.hexId}`}
              chain={chain}
              // disabled={currentWallet?.chain.hexId !== chain.hexId}
              selectedChainIds={selectedChainIds}
              setSelectedChainIds={setSelectedChainIds}
            />
          ))}

          <div className="font-['Cal Sans'] text-base font-semibold">Collection</div>

          <div className='relative inline-flex items-center '>
            <input
              placeholder='Filter'
              type='text'
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className='rounded-xl self-strech inline-flex h-12 w-full items-center justify-between rounded-lg border border-jacarta-100 bg-white p-3 py-[0.4475rem] text-jacarta-700 placeholder-jacarta-500 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white'
              data-testid='bulkToolFilter'
            />

            <div className='absolute right-3.5 top-4 opacity-60'>
              <FaSearch />
            </div>
          </div>
          {!isWrapping && (
            <>
              {filteredDn404Collections?.length ? (
                <>
                  {filteredDn404Collections?.map((collection) => (
                    <UWUnwrapCollections
                      key={collection.id}
                      currentWallet={currentWallet}
                      collection={collection}
                      direction={status === 'unwrapping'}
                      selectedCollection={selectedCollection}
                      setSelectedCollection={setSelectedCollection}
                    />
                  ))}
                </>
              ) : (
                <p>The collection was not found...</p>
              )}
            </>
          )}
          {isWrapping && (
            <>
              {filteredCollections?.length ? (
                <>
                  {filteredCollections?.map((collection) => (
                    <UWCollections
                      key={collection.id}
                      currentWallet={currentWallet}
                      collection={collection}
                      setApprovedCollections={setApprovedCollections}
                      selectedCollection={selectedCollection}
                      setSelectedCollection={setSelectedCollection}
                      direction={status === 'unwrapping'}
                    />
                  ))}
                </>
              ) : (
                <p>The collection was not found...</p>
              )}
            </>
          )}
        </div>
      </div>

      {/*--------------------------------2. Bulk Tool-----------------------------------*/}

      {/* <div className='col-span-12 border-t-2 max-lg:border-t-accent/30 lg:col-span-8 lg:border-t-0 xl:col-span-8'> */}
      <div className='w-full'>
        <div className='px-4 max-lg:mt-6 lg:px-0 lg:pr-4'>
          <div className="font-['Cal Sans'] mb-6 mt-4 flex items-center justify-between text-xl font-semibold text-accent">
            <div>2. Universal Wrapper (Beta)</div>
          </div>
          <div className='mb-3 flex flex-col justify-between gap-2.5 align-bottom'>
            <div className='flex w-full flex-col justify-between'>
              <div className='flex flex-row items-center justify-between gap-2'>
                <SearchInput value={searchText} onChange={setSearchText} />
                <DropdownDynamic
                  items={sortSelectItems}
                  value={sortSelectItems.find((i) => i.id === sort)}
                  onSelect={(val) => {
                    setSort(val.id);
                  }}
                  className='w-fit'
                />
              </div>
              <div className='flex flex-row items-center gap-2'>
                {(query?.tokenIds || searchText) && (
                  <button
                    className='flex w-fit whitespace-nowrap text-sm font-medium text-accent'
                    onClick={(e) => {
                      refreshHooks();
                      resetSelections();
                      router.replace(
                        `${window.location.pathname}${
                          query?.active ? `?active=${query.active}` : ''
                        }`,
                        undefined,
                        { shallow: true },
                      );
                    }}
                  >
                    Clear Filter
                  </button>
                )}
              </div>
            </div>
            <div className='flex flex-row items-center justify-between gap-2.5'>
              <div className="-font-['Roboto'] flex flex-row items-center justify-center gap-1.5 text-sm font-medium">
                <input
                  type='checkbox'
                  onChange={toggleSelectAll}
                  checked={selectAll}
                  disabled={controlDisabled}
                  className={`h-6 w-6 cursor-pointer  rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600 ${
                    controlDisabled ? 'opacity-25' : ''
                  }`}
                  data-testid='bulkToolSelectAll'
                />
                <span className='flex flex-row items-center justify-center'>
                  Select All{' '}
                  <span className='ml-1 text-sm text-accent'>
                    ({selectedItems?.length}/{items?.length})
                  </span>
                </span>
              </div>
              <div className='flex w-fit gap-1'>
                <div className='flex flex-row items-center gap-2'>
                  <button
                    className={`rounded-xl -font-['Roboto'] inline-flex h-[43px] w-[85px] items-center justify-center gap-2.5 border-2 border-accent bg-transparent px-2 text-base font-semibold text-accent ${
                      buttonDisabled ? 'opacity-25' : 'hover:bg-accent-dark hover:text-white'
                    }`}
                    disabled={buttonDisabled}
                    onClick={isWrapping ? handleWrap : handleUnwrap}
                    data-testid='bulkToolTransferButton'
                  >
                    {isWrapping ? 'Wrap' : 'Unwrap'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!isWrapping && (
            <Tooltip
              className=''
              title={
                <p>
                  All unwrappable NFTs in the vault wallet are visible. For each collection, you can
                  unwrap as many DN404s as you have in your wallet.
                </p>
              }
              placement='bottom'
            >
              <div className='mb-2 flex items-center '>
                <BsInfo />
                <p className='text-sm italic'>Wrapped NFTs in the Vault</p>
              </div>
            </Tooltip>
          )}

          {items?.length > 0 ? (
            items.map((item) => {
              // Find the globalBid that matches the collectionAddress of the current item

              return (
                <UWSelectItem
                  key={item?.id}
                  item={item}
                  selectedItems={selectedItems}
                  selectAll={selectAll}
                  setSelectedItems={setSelectedItems}
                  useSamePrice={useSamePrice}
                  samePriceValue={samePriceValue}
                  direction={status === 'unwrapping'}
                  approvedCollections={approvedCollections}
                  status={status}
                  //isAnyGlobalBid={hasMatchingGlobalBid ? matchingGlobalBid : null}
                />
              );
            })
          ) : (
            <p className='mt-5 text-center'>No data yet...</p>
          )}
        </div>
        {/* Pagination  */}
        {items?.length > 0 && (
          <UWPagination
            page={page}
            goToPage={goToPage}
            totalPages={totalPages}
            totalCount={totalCount}
          />
        )}
      </div>
    </div>
  );
};
