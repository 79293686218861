import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import PropTypes from 'prop-types';

const UWPagination = ({ page, totalPages, goToPage, totalCount }) => {
  const [buttons, setButtons] = useState([]);
  const [forward, setForward] = useState(true);

  const handleForwardPage = () => {
    setForward(true);
    goToPage(page + 1);
  };

  const handleBackPage = () => {
    setForward(false);
    goToPage(page - 1);
  };

  const getPageButtons = () => {
    let buttons = [1];

    if (totalPages <= 4) {
      for (let i = 2; i <= totalPages; i++) {
        buttons.push(i);
      }
    } else {
      const currPage = page + 1;
      if (currPage == 0) {
        buttons = [];
      } else if (currPage < 4) {
        buttons = [1, 2, 3];
      } else {
        const startPage = currPage;
        let endPage = startPage - 1;

        for (let i = endPage; i <= startPage; i++) {
          buttons.push(i);
        }
      }

      if (currPage < totalPages) {
        if (forward) {
          if (totalPages > 4 && currPage + 1 < totalPages) {
            buttons.push('...');
          }
          if (totalPages - currPage == 1) buttons.splice(1, 0, '...');
        } else if (currPage > 3) buttons.splice(1, 0, '...');
        else {
          buttons.push('...');
        }

        buttons.push(totalPages);
      }

      if (totalPages == currPage) {
        buttons.splice(1, 0, '...');
        buttons.splice(buttons.length - 2, 0, totalPages - 2);
      }
    }

    setButtons(buttons);
  };

  useEffect(() => {
    if (totalPages) getPageButtons();
  }, [totalCount, page, totalPages]);

  return (
    <div className='mx-auto  mt-5 flex w-[400px] items-center justify-between gap-4 border-[#E7E8EC] '>
      <div className='relative'>
        <button
          className={`h-10 w-10 justify-center rounded border-[#E7E8EC]  px-2 ${
            page === 0 ? 'opacity-30' : ''
          }`}
          onClick={handleBackPage}
          disabled={page === 0}
          data-testid='bulkToolBackward'
        >
          <IoIosArrowBack className='h-6 w-6' />
        </button>
      </div>
      <div className='flex justify-center gap-4 border-[#E7E8EC] '>
        {buttons.map((p, index) => (
          <div key={`${p}-${index}`} className='relative'>
            {p === '...' ? (
              <span className='flex h-10 w-10 items-center justify-center text-base  font-semibold'>
                {p}
              </span>
            ) : (
              <button
                className={`h-10 w-10 justify-center rounded-md border border-[#dadde6] px-2 text-base  font-semibold ${
                  page === p - 1
                    ? 'bg-accent bg-opacity-30 focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
                    : ''
                }`}
                data-testid='bulkToolGoToPage'
                onClick={() => goToPage(p - 1)}
              >
                {p}
              </button>
            )}
          </div>
        ))}
      </div>
      <div className='relative'>
        <button
          className={`h-10 w-10 justify-center rounded border-[#dadde6]  px-2 ${
            page === totalPages - 1 ? 'opacity-30' : ''
          }`}
          onClick={handleForwardPage}
          disabled={page === totalPages - 1}
          data-testid='bulkToolForward'
        >
          <IoIosArrowForward className='h-6 w-6' />
        </button>
      </div>
    </div>
  );
};

UWPagination.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  goToPage: PropTypes.func,
  totalCount: PropTypes.number,
};

export default UWPagination;
