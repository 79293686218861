import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { UWImportErc20Buttons } from './UWImportErc20Buttons';
import PropTypes from 'prop-types';

export const UWUnwrapCollections = ({ collection, selectedCollection, setSelectedCollection }) => {
  const [image, setImage] = useState(null);
  const { address: walletAddress } = useAccount();

  const toggleCollection = (collection) => {
    const collectionExists = selectedCollection.find((col) => col === collection?.address);

    if (collectionExists) {
      const newSelectedCollection = selectedCollection.filter((col) => col !== collection?.address);
      setSelectedCollection(newSelectedCollection);
    } else {
      setSelectedCollection([...selectedCollection, collection?.address]);
    }
  };

  useEffect(() => {
    setImage(getCdnUrl(collection?.profilePhotoPath, 'thumbnail', collection?.updatedAt));
  }, [collection, walletAddress]);

  return (
    <div>
      <div className='inline-flex w-full items-center justify-between gap-2'>
        <div className='flex w-full flex-row items-center gap-2'>
          <input
            type='checkbox'
            onChange={() => toggleCollection(collection)}
            checked={selectedCollection?.some((f) => f === collection?.address)}
            className='h-6 w-6 cursor-pointer rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600'
            data-testid='bulkToolCollection'
          />
          <div className='min-h-[48px] min-w-[48px] rounded'>
            <ImageFixedAO image={image} alt={collection?.address} bottomRounded />
          </div>
          <div className='flex w-full flex-col justify-start gap-3 truncate'>
            <p className="-font-['Roboto'] w-full truncate text-base font-bold">
              {collection?.name}
            </p>
            <div className="-font-['Roboto'] text-sm font-normal">
              {collection?.count > 1
                ? collection?.count + ' Unwrappable Items'
                : collection?.count + ' Unwrappable Item'}
            </div>
          </div>
          <UWImportErc20Buttons collection={collection} />
        </div>
      </div>
    </div>
  );
};

UWUnwrapCollections.propTypes = {
  collection: PropTypes.object,
  selectedCollection: PropTypes.array,
  setSelectedCollection: PropTypes.func,
};
