import { useEffect, useState } from 'react';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { getItemImagePath } from '../../helpers/path';
import PropTypes from 'prop-types';

export const UWSelectItem = ({
  item,
  selectedItems,
  setSelectedItems,
  approvedCollections,
  direction,
  status,
}) => {
  const [image, setImage] = useState(null);

  const collection = item?.collection;
  const isWrapping = status === 'wrapping';

  const toggleItem = (item) => {
    if (selectedItems?.find((i) => i.id === item.id)) {
      setSelectedItems(selectedItems?.filter((i) => i.id !== item.id));
    } else if (!isWrapping || approvedCollections[item?.collectionAddress])
      setSelectedItems([...selectedItems, item]);
  };

  const isEnabled = !direction ? !isWrapping || approvedCollections[item?.collectionAddress] : true;

  useEffect(() => {
    setImage(getItemImagePath(item, 'thumbnail'));
  }, [item.imageFtpPath]);

  return (
    <div className={`border-t border-jacarta-100 ${isEnabled ? '' : 'opacity-25'}`}>
      <div className='my-2 flex justify-between py-2.5 dark:border-jacarta-600 md:gap-5'>
        <div className='flex flex-1 items-center'>
          <input
            type='checkbox'
            checked={
              (!isWrapping || approvedCollections[item?.collectionAddress]) &&
              !!selectedItems?.find((i) => i.id === item.id)
            }
            onChange={() => toggleItem(item)}
            disabled={!isEnabled}
            className={'mr-6 h-6 w-6 rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600 '.concat(
              isEnabled ? 'cursor-pointer' : 'cursor-not-allowed',
            )}
            data-testid='bulkToolItem'
          />

          <div className='mr-3 h-12 w-12 rounded '>
            <ImageFixedAO image={image} alt={collection?.address} bottomRounded />
          </div>
          <div className='inline-flex flex-col items-start justify-start gap-1 md:gap-3'>
            <div className='h-[18px] max-w-[160px] overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold sm:max-w-[240px]'>
              {item?.name}
            </div>
            <div className='h-[18px] max-w-[160px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal sm:max-w-[240px] md:max-w-[240px]'>
              {item?.collection?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UWSelectItem.propTypes = {
  item: PropTypes.object,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  approvedCollections: PropTypes.object,
  direction: PropTypes.string,
  status: PropTypes.string,
};
